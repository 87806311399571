import "./category.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import LoadingGettingItems from "../../components/loadingGetttingItems/LoadingGettingItems";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCategoryById,
  getCategoryList,
  getSubCategoryList,
  deleteSubCategory,
} from "../../actions/categoryActions";
import {
  setOpenDeleteSubCategoryModal,
  setIsErrorDeleteSubCategory,
} from "../../reducers/CategoryReducer";
import { setUserInfo } from "../../reducers/AuthReducer";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import CategoryModal from "../../components/Modal/categoryModal/CategoryModal";
import SubCategoryModal from "../../components/Modal/subCategoryModal/SubCategoryModal";
import toast, { Toaster } from "react-hot-toast";
const Category = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );
  const [itemToDelete, setItemToDelete] = useState(null);
  const [successEditCategory, setSuccessEditCategory] = useState(false);
  const [successAddSubCategory, setSuccessAddSubCategory] = useState(false);
  const subCategoryColumns = [
    {
      field: "label",
      headerName: "label",
      flex: 1,
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`/categories/view/sub/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                setItemToDelete(params.row);
                dispatch(setOpenDeleteSubCategoryModal({ value: true }));
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const {
    categoryToEdit,
    isLoadingUpdateCategory,
    isLoadingAddSubCategory,
    openDeleteSubCategoryModal,
    isLoadingDeleteSubCategory,
    isLoadingGetCategory,
    isErrorGetCategory,
    isErrorDeleteSubCategory,
  } = useSelector((state) => state.category);

  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [category, setCategory] = useState(categoryToEdit);
  useEffect(() => {
    if (categoryToEdit === null || categoryToEdit._id !== id) {
      dispatch(getCategoryById(id));
    } else {
      setCategory(categoryToEdit);
      setSubCategories(settingSubCategories(categoryToEdit));
    }
  }, [categoryToEdit]);
  useEffect(() => {
    if (successEditCategory) {
      toast.success("category updated succesfully");
      setSuccessEditCategory(false);
    }
    if (successAddSubCategory) {
      toast.success("subCategory added succesfully");
      setSuccessAddSubCategory(false);
    }
  }, [successEditCategory, successAddSubCategory]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  const settingSubCategories = (category) => {
    if (category && category.subCategories.length > 0) {
      return category?.subCategories;
    } else {
      return [];
    }
  };
  const [subCategories, setSubCategories] = useState(
    settingSubCategories(categoryToEdit)
  );
  const [onEdit, setOnEdit] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const handleDelete = () => {
    dispatch(deleteSubCategory(itemToDelete._id)).then((res) => {
      if (res.payload?.status) {
        dispatch(getSubCategoryList()).then(() => dispatch(getCategoryList()));
      }
    });
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {isErrorGetCategory ? (
          <div className="errorContainer">
            <h1 className="errorText"> server error 😞 </h1>
          </div>
        ) : (
          <>
            <div className="top">
              <Toaster />
              <div className="left">
                <div
                  className="editButton"
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  Edit
                </div>
                <div
                  className="addButton"
                  onClick={() => {
                    setOnAdd(true);
                  }}
                >
                  Add SubCategory
                </div>
                <h1 className="title">Information</h1>
                {isLoadingGetCategory ? (
                  <Spinner />
                ) : (
                  <div className="item">
                    <div className="details">
                      <h1 className="itemTitle">{categoryToEdit?.label}</h1>
                      <div className="detailItem">
                        <span className="itemKey">Description: </span>
                        <span className="itemValue">
                          {categoryToEdit?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {onEdit && (
                <CategoryModal
                  categoryToEdit={category}
                  isAdd={false}
                  loading={isLoadingUpdateCategory}
                  title={"Edit Category"}
                  closeModal={() => setOnEdit(false)}
                  showSuccessMessage={() => setSuccessEditCategory(true)}
                />
              )}
              {onAdd && (
                <SubCategoryModal
                  isAdd={true}
                  closeModal={() => setOnAdd(false)}
                  title={"Add SubCategory"}
                  subCategoryToEdit={null}
                  loading={isLoadingAddSubCategory}
                  showSuccessMessage={() => setSuccessAddSubCategory(true)}
                  category={category}
                />
              )}
            </div>

            <div className="bottom-category">
              {openDeleteSubCategoryModal ? (
                <Modal
                  isOpen={openDeleteSubCategoryModal}
                  onCancel={() =>
                    dispatch(setOpenDeleteSubCategoryModal({ value: false }))
                  }
                  onConfirm={handleDelete}
                  itemName={itemToDelete?.label}
                  isLoading={isLoadingDeleteSubCategory}
                  isError={isErrorDeleteSubCategory}
                  onOk={() => {
                    dispatch(setOpenDeleteSubCategoryModal({ value: false }));
                    dispatch(setIsErrorDeleteSubCategory({ value: false }));
                  }}
                />
              ) : (
                <div className="datatable">
                  {categoryToEdit !== null && (
                    <div className="datatableTitle">
                      List subCategories by {categoryToEdit.label}
                    </div>
                  )}
                  {isLoadingGetCategory ? (
                    <LoadingGettingItems />
                  ) : (
                    <DataGrid
                      className="datagrid"
                      rows={subCategories}
                      columns={subCategoryColumns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                      getRowId={(row) => row._id}
                      //checkboxSelection
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Category;
