import "./products.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ProductDatatable from "./productDataTable/ProductDatatable";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";

const Products = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      dispatch(setUserInfo({ value: user }));
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <ProductDatatable />
      </div>
    </div>
  );
};

export default Products;
