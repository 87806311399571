import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_LOGIN,
  API_LOGOUT,
  BASE_URL,
  api,
  API_GET_USERS_BY_ROLE,
  USER_ROLE_BUYER,
  USER_ROLE_SELLER,
  API_UPDATE_USER,
  API_DELETE_USER,
  API_GET_SELLERS,
  API_GET_USER,
  API_GET_ADMINS,
  API_ADD_ADMIN,
  API_DELETE_ADMIN,
} from "../constant/endpoints";
import { USER_TOKEN, USER_EMAIL } from "../constant/index";
export const userLogin = createAsyncThunk(API_LOGIN, async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}${API_LOGIN}`, data);
    if (response.status && response.data?.status) {
      localStorage.setItem(USER_TOKEN, response.data.data.accessToken);
      localStorage.setItem(USER_EMAIL, response.data.data.email);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const add_admin = createAsyncThunk(API_ADD_ADMIN, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await axios.post(`${BASE_URL}${API_ADD_ADMIN}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
});
export const getBuyerList = createAsyncThunk(
  API_GET_USERS_BY_ROLE,
  async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(
        `${API_GET_USERS_BY_ROLE}${USER_ROLE_BUYER}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getSellerList = createAsyncThunk(API_GET_SELLERS, async () => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_SELLERS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const getAdminList = createAsyncThunk(API_GET_ADMINS, async () => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_ADMINS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const getUser = createAsyncThunk(API_GET_USER, async (id) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_USER}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const updateUser = createAsyncThunk(API_UPDATE_USER, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const response = await api.put(`${API_UPDATE_USER}${data?._id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
      transformRequest: (data, headers) => {
        return formData;
      },
    });
    return response.data;
  } catch (error) {
    return false;
  }
});
export const deleteUser = createAsyncThunk(API_DELETE_USER, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const dataToSent = {
      role: data.role,
    };
    const result = await fetch(`${BASE_URL}${API_DELETE_USER}${data.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSent),
    });
    const response = await result.json();
    console.log(response);
    return response;
  } catch (error) {
    return false;
  }
});
export const deleteAdmin = createAsyncThunk(API_DELETE_ADMIN, async (id) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.delete(`${API_DELETE_ADMIN}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return false;
  }
});
