import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Buyers from "./pages/buyers/Buyers";
import Admins from "./pages/admins/Admins";
import Buyer from "./pages/buyer/Buyer";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Sellers from "./pages/sellers/Sellers";
import Seller from "./pages/seller/Seller";
import Products from "./pages/products/Products";
import Product from "./pages/product/Product";
import Categories from "./pages/categories/Categories";
import Category from "./pages/category/Category";
import SubCategory from "./pages/subCategory/SubCategory";
import Walets from "./pages/walets/Walets";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="admins">
              <Route index element={<Admins />} />
            </Route>
            <Route path="buyers">
              <Route index element={<Buyers />} />
              <Route path="view/:id" element={<Buyer />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="sellers">
              <Route index element={<Sellers />} />
              <Route path="view/:id" element={<Seller />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<Products />} />
              <Route path="view/:id" element={<Product />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="categories">
              <Route index element={<Categories />} />
              <Route path="view/:id" element={<Category />} />
              <Route path="view/sub/:id" element={<SubCategory />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="walets">
              <Route index element={<Walets />} />
              {/* <Route path="view/:id" element={<Category />} />
              <Route path="view/sub/:id" element={<SubCategory />} /> */}
              {/* <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              /> */}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
