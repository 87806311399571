import "./sellers.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import SellerDatatable from "./sellerDataTable/SellerDatatable";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
const Sellers = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo({ value: user }));
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <SellerDatatable />
      </div>
    </div>
  );
};

export default Sellers;
