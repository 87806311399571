import "./buyer.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
import { updateUser, getUser } from "../../actions/authActions";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import { USER_TOKEN, IMAGE_BASE } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
const Buyer = () => {
  const { id } = useParams();
  const { buyerToEdit, isLoadingUpdateBuyer, isServerErrorUpdateBuyer } =
    useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.auth);
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [user, setUser] = useState(buyerToEdit);
  const [onEdit, setOnEdit] = useState(false);
  useEffect(() => {
    if (buyerToEdit === null || buyerToEdit._id !== id) {
      dispatch(getUser(id));
    } else {
      setUser(buyerToEdit);
    }
  }, [buyerToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setUser((prev) => ({ ...prev, [id]: value }));
  };
  const handleEditBuyer = (event) => {
    event.preventDefault();
    dispatch(updateUser(user));
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {isServerErrorUpdateBuyer ? (
          <div className="errorContainer">
            <h1 className="errorText"> server error 😞 </h1>
          </div>
        ) : (
          <>
            <div className="top">
              <div className="left">
                {isLoadingUpdateBuyer ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="editButton" onClick={() => setOnEdit(true)}>
                      Edit
                    </div>
                    <h1 className="title">Information</h1>
                    <div className="item">
                      <img
                        src={IMAGE_BASE + buyerToEdit?.picture}
                        alt=""
                        className="itemImg"
                        crossOrigin="anonymous"
                      />
                      <div className="details">
                        <h1 className="itemTitle">
                          {buyerToEdit?.firstName} {buyerToEdit?.lastName}
                        </h1>
                        <div className="detailItem">
                          <span className="itemKey">Email: </span>
                          <span className="itemValue">
                            {buyerToEdit?.email}
                          </span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Phone:</span>
                          <span className="itemValue">
                            {buyerToEdit?.phone}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="right">
                <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
              </div>
            </div>
            {onEdit && (
              <div className="bottom">
                <div className="left">
                  {user !== null && (
                    <img
                      src={
                        file && file !== ""
                          ? URL.createObjectURL(file)
                          : IMAGE_BASE + user.picture
                      }
                      alt=""
                      crossOrigin="anonymous"
                    />
                  )}
                </div>
                <div className="right">
                  {isLoadingUpdateBuyer ? (
                    <Spinner />
                  ) : (
                    user !== null && (
                      <form onSubmit={handleEditBuyer}>
                        <div className="formInput">
                          <label htmlFor="file">
                            Image:{" "}
                            <DriveFolderUploadOutlinedIcon className="icon" />
                          </label>
                          <input
                            type="file"
                            id="file"
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                picture: e.target.files[0],
                              }));
                              setFile(e.target.files[0]);
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="formInput">
                          <label>First Name</label>
                          <input
                            id="firstName"
                            type="text"
                            value={user.firstName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="formInput">
                          <label>Last Name</label>
                          <input
                            id="lastName"
                            type="text"
                            value={user.lastName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="formInput">
                          <label>Email</label>
                          <input
                            id="email"
                            type="mail"
                            value={user.email}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="formInput">
                          <label>Phone</label>
                          <input
                            id="phone"
                            type="text"
                            value={user.phone}
                            onChange={handleInputChange}
                          />
                        </div>
                        <button>Send</button>
                      </form>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Buyer;
