import "./chartGlob.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { getStats_buyers_sellers } from "../../actions/statsActions";
import { useDispatch, useSelector } from "react-redux";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const years = [2022, 2023, 2024, 2025];
const ChartGlob = ({
  aspect,
  title,
  data,
  year,
  setYear,
  month,
  setMounth,
  mounths,
  problem,
}) => {
  const dispatch = useDispatch();
  const handleYearChange = (event) => {
    const {
      target: { value },
    } = event;
    const el = typeof value === "string" ? value.split(",") : value;
    let T = [el];
    const data = {
      year: el,
      month: month.length > 0 && month[0] != "all" ? month[0] : null,
    };
    dispatch(getStats_buyers_sellers(data));
    setYear(T);
  };
  const handleMounthChange = (event) => {
    const {
      target: { value },
    } = event;
    const el = typeof value === "string" ? value.split(",") : value;
    let T = [el];
    const data = {
      year: year.length > 0 ? year[0] : null,
      month: el != "all" ? el : null,
    };
    dispatch(getStats_buyers_sellers(data));
    setMounth(T);
  };
  return (
    <div className="chart">
      {problem ? (
        <>
          <div className="errorContainer">
            <h1 className="errorText"> something went wrong 😞 </h1>
          </div>
        </>
      ) : (
        <>
          <div className="title">
            {title}
            <div className="chartDropDown">
              <FormControl sx={{ m: 1, width: 100 }}>
                <InputLabel id="demo-multiple-checkbox-label">Year</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={year}
                  onChange={handleYearChange}
                  input={<OutlinedInput label="Year" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {years.map((label) => (
                    <MenuItem key={label} value={label}>
                      <Checkbox checked={year.indexOf(label) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="chartDropDown">
              <FormControl sx={{ m: 1, width: 100 }}>
                <InputLabel id="demo-multiple-checkbox-label">Month</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={month}
                  onChange={handleMounthChange}
                  input={<OutlinedInput label="Month" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {mounths.map((label) => (
                    <MenuItem key={label} value={label}>
                      <Checkbox checked={month.indexOf(label) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <ResponsiveContainer width="100%" aspect={aspect}>
            {}
            <AreaChart
              width={730}
              height={200}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="sellers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#5B93FF" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#5B93FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="buyers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FFD66B" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FFD66B" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" stroke="gray" />
              <YAxis domain={[0, 20]} />
              <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="sellers"
                stroke="#5B93FF"
                fillOpacity={1}
                fill="url(#sellers)"
              />
              <Area
                type="monotone"
                dataKey="buyers"
                stroke="#FFD66B"
                fillOpacity={1}
                fill="url(#buyers)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

export default ChartGlob;
