import "./categoryDataTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteCategoryModal,
  setIsErrorDeleteCategory,
} from "../../../reducers/CategoryReducer";
import Modal from "../../../components/Modal/Modal";
import { Link } from "react-router-dom";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { useEffect, useState } from "react";
import CategoryModal from "../../../components/Modal/categoryModal/CategoryModal";
import toast, { Toaster } from "react-hot-toast";
import {
  getCategoryList,
  deleteCategory,
} from "../../../actions/categoryActions";
const CategoryDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successAddCategory, setSuccessAddCategory] = useState(false);
  const [onAddCategory, setOnAddCategory] = useState(false);
  const {
    categoryListToExecute,
    openDeleteCategoryModal,
    isLoadingDeleteCategory,
    isLoadingGetCategories,
    isLoadingAddCategory,
    isErrorGetCategories,
    isErrorDeleteCategory,
  } = useSelector((state) => state.category);
  useEffect(() => {
    if (categoryListToExecute.length == 0) {
      dispatch(getCategoryList());
    }
  }, []);
  useEffect(() => {
    if (successAddCategory) {
      toast.success("category added succesfully");
      setSuccessAddCategory(false);
    }
  }, [successAddCategory]);
  const categoryColumns = [
    {
      field: "label",
      headerName: "label",
      flex: 1,
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
    },

    {
      field: "nbSubs",
      headerName: "subCategories",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">{params.row?.subCategories?.length}</div>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDelete = () => {
    dispatch(deleteCategory(itemToDelete._id));
  };
  const handleAddNewCategory = () => {
    setOnAddCategory(true);
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                setItemToDelete(params.row);
                dispatch(setOpenDeleteCategoryModal({ value: true }));
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="top">
        <Toaster />
        {onAddCategory && (
          <CategoryModal
            closeModal={() => setOnAddCategory(false)}
            isAdd={true}
            title={"Add Category"}
            loading={isLoadingAddCategory}
            categoryToEdit={null}
            showSuccessMessage={() => setSuccessAddCategory(true)}
          />
        )}
      </div>
      <div className="bottom">
        <div className="datatable">
          <div className="datatableTitle">
            List Categories
            {!onAddCategory && !isErrorGetCategories && (
              <Link className="link" onClick={handleAddNewCategory}>
                Add New Category
              </Link>
            )}
          </div>
          {openDeleteCategoryModal ? (
            <Modal
              isOpen={openDeleteCategoryModal}
              onCancel={() =>
                dispatch(setOpenDeleteCategoryModal({ value: false }))
              }
              onConfirm={handleDelete}
              itemName={itemToDelete?.label}
              isLoading={isLoadingDeleteCategory}
              isError={isErrorDeleteCategory}
              onOk={() => {
                dispatch(setOpenDeleteCategoryModal({ value: false }));
                dispatch(setIsErrorDeleteCategory({ value: false }));
              }}
            />
          ) : isLoadingGetCategories ? (
            <LoadingGettingItems />
          ) : isErrorGetCategories ? (
            <div className="errorContainer">
              <h1 className="errorText"> server error 😞 </h1>
            </div>
          ) : (
            <DataGrid
              className="datagrid"
              rows={categoryListToExecute}
              columns={categoryColumns.concat(actionColumn)}
              pageSize={9}
              rowsPerPageOptions={[9]}
              getRowId={(row) => row._id}
              //checkboxSelection
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryDatatable;
