import "./product.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { updateProduct, getProduct } from "../../actions/productActions";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import { setProblemInUpdateProduct } from "../../reducers/ProductReducer";
import {
  USER_TOKEN,
  IMAGE_BASE,
  subCatygoriesForSizes,
  subCatygoriesForColors,
} from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
import AlertModal from "../../components/AlertModal/AlertModal";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Product = () => {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const { productToEdit, isLoadingUpdateProduct, problemUpdateProduct } =
    useSelector((state) => state.product);
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );

  const settingSubcategoryNames = (List) => {
    if (productToEdit && List && List.length > 0) {
      const list = List.filter(
        (el) => el?.category?._id === productToEdit?.subcategory?.category
      );
      const names = list[0]?.subcategories.map((sub) => sub?.label);
      return names;
    } else {
      return [];
    }
  };
  const settingInitialCategory = (product) => {
    let category = [];
    if (product !== null) {
      category = product?.seller?.category
        .filter((cat) => cat._id === product?.subcategory?.category)
        .map((c) => c.label);
    }
    return category;
  };
  const settingInitialSubCategory = (product) => {
    let T = [];
    if (product !== null) {
      T.push(product?.subcategory?.label);
    }
    return T;
  };
  const settingCategoriesNames = (product) => {
    let T = [];
    if (product !== null) {
      T = productToEdit?.seller?.category
        .filter((el) => el.subCategories.length > 0)
        .map((c) => c.label);
    }
    return T;
  };
  const [categoriesNames, setCategoriesNames] = useState(
    settingCategoriesNames(productToEdit)
  );

  const [subCategoriesNames, setSubCategoriesNames] = useState(
    settingSubcategoryNames(subCategoryList)
  );
  const [currentCategorySelected, setCurrentCategorySelected] = useState(
    settingInitialCategory(productToEdit)
  );
  const [currentSubCategorySelected, setCurrentSubCategorySelected] = useState(
    settingInitialSubCategory(productToEdit)
  );
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    const filtred = subCategoryList.find(
      (com) => com?.category?.label === value
    );
    if (filtred) {
      const newSubsList = filtred.subcategories.map((sub) => sub.label);
      setSubCategoriesNames(newSubsList);
    }
    setCurrentSubCategorySelected([]);
    setCurrentCategorySelected(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleSubCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentSubCategorySelected(
      typeof value === "string" ? value.split(",") : value
    );
    const subs = subCategoryList.find(
      (el) => el.category.label === currentCategorySelected[0]
    ).subcategories;
    const T = subs.filter((sub) => sub.label === value).map((res) => res._id);
    const id = T[0];
    setProduct((prev) => ({ ...prev, subcategory: id }));
  };
  const dispatch = useDispatch();
  const [product, setProduct] = useState(productToEdit);
  const [onEdit, setOnEdit] = useState(false);
  useEffect(() => {
    if (productToEdit === null || productToEdit._id !== id) {
      dispatch(getProduct(id));
    } else {
      setProduct(productToEdit);
      setCurrentCategorySelected(settingInitialCategory(productToEdit));
      setCurrentSubCategorySelected(settingInitialSubCategory(productToEdit));
      setSubCategoriesNames(settingSubcategoryNames(subCategoryList));
      setCategoriesNames(settingCategoriesNames(productToEdit));
    }
  }, [productToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setProduct((prev) => ({ ...prev, [id]: value }));
  };
  const handleEditProduct = async (event) => {
    event.preventDefault();
    dispatch(updateProduct(product));
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {problemUpdateProduct ? (
          <AlertModal
            isOpen={problemUpdateProduct !== ""}
            onConfirm={() => dispatch(setProblemInUpdateProduct({ value: "" }))}
            itemName={problemUpdateProduct}
          />
        ) : (
          <>
            <div className="top">
              <div className="left">
                {isLoadingUpdateProduct ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="editButton" onClick={() => setOnEdit(true)}>
                      Edit
                    </div>
                    <h1 className="title">Information</h1>
                    {productToEdit && (
                      <div className="item">
                        <img
                          src={IMAGE_BASE + productToEdit.pictures1}
                          alt=""
                          className="itemImg"
                          crossOrigin="anonymous"
                        />
                        <div className="details">
                          <h1 className="itemTitle">{productToEdit.label}</h1>
                          {productToEdit?.brandName && (
                            <div className="detailItem">
                              <span className="itemKey">Brand: </span>
                              <span className="itemValue">
                                {productToEdit?.brandName}
                              </span>
                            </div>
                          )}
                          <div className="detailItem">
                            <span className="itemKey">Company:</span>
                            <span className="itemValue">
                              {productToEdit?.seller?.companyName}
                            </span>
                          </div>
                          <div className="detailItem">
                            <span className="itemKey">Address:</span>
                            <span className="itemValue">
                              {productToEdit?.seller?.address}
                            </span>
                          </div>
                          <div className="detailItem">
                            <span className="itemKey">Description:</span>
                            <span className="itemValue">
                              {productToEdit.description}
                            </span>
                          </div>
                          <div className="detailItem">
                            <span className="itemKey">Price:</span>
                            <span className="itemValue">
                              {productToEdit.price}
                            </span>
                          </div>
                          {subCatygoriesForSizes.includes(
                            product?.subcategory?.label
                          ) && (
                            <div className="detailItem">
                              <span className="itemKey">Sizes:</span>
                              {productToEdit.sizes.map((size) => (
                                <span
                                  className="itemValue"
                                  style={{ marginLeft: "10px" }}
                                >
                                  {size}
                                </span>
                              ))}
                            </div>
                          )}
                          {subCatygoriesForColors.includes(
                            product?.subcategory?.label
                          ) && (
                            <div className="detailItem">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <span className="itemKey">Colors:</span>

                                {productToEdit.colors.map((color) => (
                                  <div
                                    style={{
                                      borderRadius: "50%",
                                      height: "25px",
                                      width: "25px",
                                      backgroundColor: color,
                                    }}
                                  ></div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="right-product">
                {product?.pictures1 && (
                  <img
                    src={IMAGE_BASE + product.pictures1}
                    alt=""
                    crossOrigin="anonymous"
                    style={{ marginLeft: "12%" }}
                  />
                )}
                {product?.pictures2 && (
                  <img
                    src={IMAGE_BASE + product.pictures2}
                    alt=""
                    crossOrigin="anonymous"
                  />
                )}
                {product?.pictures3 && (
                  <img
                    src={IMAGE_BASE + product.pictures3}
                    alt=""
                    crossOrigin="anonymous"
                  />
                )}
                {/* <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" /> */}
              </div>
            </div>
            {onEdit && product && (
              <div className="bottom">
                {isLoadingUpdateProduct ? (
                  <Spinner />
                ) : (
                  <form onSubmit={handleEditProduct}>
                    <div className="formInput">
                      <label>Label</label>
                      <input
                        id="label"
                        type="text"
                        value={product.label}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="formInput">
                      <label>Brand</label>
                      <input
                        id="brandName"
                        type="text"
                        value={product.brandName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="formInput">
                      <label>Description</label>
                      <textarea
                        id="description"
                        type="text"
                        className="description"
                        value={product.description}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="formInput">
                      <label>Price</label>
                      <input
                        id="price"
                        type="text"
                        value={product.price}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          value={currentCategorySelected}
                          onChange={handleCategoryChange}
                          input={<OutlinedInput label="Category" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {categoriesNames.map((label) => (
                            <MenuItem key={label} value={label}>
                              <Checkbox
                                checked={
                                  currentCategorySelected.indexOf(label) > -1
                                }
                              />
                              <ListItemText primary={label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          SubCategory
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          value={currentSubCategorySelected}
                          onChange={handleSubCategoryChange}
                          input={<OutlinedInput label="SubCategory" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {subCategoriesNames.map((label) => (
                            <MenuItem key={label} value={label}>
                              <Checkbox
                                checked={
                                  currentSubCategorySelected?.indexOf(label) >
                                  -1
                                }
                              />
                              <ListItemText primary={label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <button className="ButtonSubmit">Send</button>
                  </form>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Product;
