const isEmpty = (value) =>
  value === null ||
  value === undefined ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

const isValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const AdminValidation = (data) => {
  let errors = {};
  if (!data?.confirmPassword || data?.confirmPassword.trim() === "") {
    errors = "Confirm password is required";
  } else if (data.password !== data.confirmPassword) {
    errors = "Passwords do not match";
  }
  if (!data?.password || data?.password.trim() === "") {
    errors = "Password is required";
  } else if (data.password.length < 8) {
    errors = "Password must be at least 8 characters";
  }
  if (!data?.email || data?.email.trim() === "") {
    errors = "Email is required";
  } else if (!isValidEmail(data.email)) {
    errors = "Invalid email format";
  }
  if (!data.phone || data.phone.trim().length < 8) {
    errors = "Phone should have 8 numbers";
  }
  if (!data?.lastName || data?.lastName.trim() === "") {
    errors = "Last name is required";
  }
  if (!data?.firstName || data?.firstName.trim() === "") {
    errors = "First name is required";
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};
