import { subCatygoriesForSizes, subCatygoriesForColors } from "../constant";
const validateImage = (file) => {
  if (!file.type.startsWith("image/")) {
    console.error("Selected file is not an image.");
    return false;
  }
  const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
  if (file.size > maxSizeInBytes) {
    console.error("Selected image is too large.");
    return false;
  }
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  if (!allowedTypes.includes(file.type)) {
    console.error("Selected image type is not supported.");
    return false;
  }
  console.log("Selected image is valid.");
  return true;
};
const isEmpty = (value) =>
  value === null ||
  value === undefined ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
export const ProductValidation = (data) => {
  let errors = {};
  if (!data?.label || data?.label.trim() === "") {
    errors = "label is required";
  }
  if (!data?.description || data?.description.trim() === "") {
    errors = "description is required";
  }
  if (!data.price || isNaN(Number(data.price)) || Number(data.price) <= 0) {
    errors = "Price must be a number greater than 0";
  }
  if (!data.subcategory || data.subcategory.trim() === "") {
    errors = "subCategory is required";
  }
  // now sizes and colors are optionel
  /*if (
    subCatygoriesForColors.includes(data?.subcategoryLabel) &&
    data.colors.length === 0
  ) {
    errors = "you should select a color";
  }
  if (
    subCatygoriesForSizes.includes(data?.subcategoryLabel) &&
    data.sizes.length === 0
  ) {
    errors = "you should select a size";
  }*/
  if (
    (!data.picture1 || data.picture1.trim() === "") &&
    (!data.picture2 || data.picture2.trim() === "") &&
    (!data.picture3 || data.picture3.trim() === "")
  ) {
    // no picture selected
    errors = "no pictures selected";
  }
  if (!data.selectedPicture1 || !validateImage(data.selectedPicture1)) {
    if (data.picture1 && data.picture1.trim() !== "") {
      errors = "first picture is not valid image";
    }
  }
  if (!data.selectedPicture2 || !validateImage(data.selectedPicture2)) {
    if (data.picture2 && data.picture2.trim() !== "") {
      errors = "second picture is not valid image";
    }
  }
  if (!data.selectedPicture3 || !validateImage(data.selectedPicture3)) {
    if (data.picture3 && data.picture3.trim() !== "") {
      errors = "third picture is not valid image";
    }
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};
