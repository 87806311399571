import "./waletDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { useEffect } from "react";
import { getAllWalets } from "../../../actions/waletActions";
import { IMAGE_BASE } from "../../../constant/index";
const WaletDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allWalets, loadingGetAllWalets, errorGetWalet } = useSelector(
    (state) => state.walet
  );
  useEffect(() => {
    if (allWalets.length === 0) {
      dispatch(getAllWalets());
    }
  }, []);
  const waletColumns = [
    {
      field: "paymentId",
      headerName: "payment_ID",
      flex: 1,
    },
    {
      field: "sellerFullName",
      headerName: "seller",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={IMAGE_BASE + params.row.seller.picture}
              alt="avatar"
              crossOrigin="anonymous"
            />
            {params.row.sellerFullName}
          </div>
        );
      },
    },
    {
      field: "paymentMethod",
      headerName: "method",
      flex: 0.5,
    },
    {
      field: "subscriptionName",
      headerName: "subscription",
      flex: 0.5,
    },
    {
      field: "amountHT",
      headerName: "excluding_VAT",
      flex: 0.5,
    },
    {
      field: "total",
      headerName: "total",
      flex: 0.5,
    },
    {
      field: "token",
      headerName: "currency",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "success" && (
              <div className={`cellWithStatus active`}>success</div>
            )}
            {params.row.status === "pending" && (
              <div className={`cellWithStatus pending`}>pending</div>
            )}
            {params.row.status === "expired" && (
              <div className={`cellWithStatus passive`}>pending</div>
            )}
            {params.row.status === "failed_payment" && (
              <div className={`cellWithStatus passive`}>failed_payment</div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">List Walets</div>
      {loadingGetAllWalets ? (
        <LoadingGettingItems />
      ) : errorGetWalet ? (
        <div className="errorContainer">
          <h1 className="errorText"> server error 😞 </h1>
        </div>
      ) : (
        <DataGrid
          rows={allWalets}
          // columns={waletColumns.concat(actionColumn)}
          columns={waletColumns}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default WaletDatatable;
