import "./navbar.scss";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  getNotificationsForAdmin,
  readNotifications,
} from "../../reducers/StatsReducer";
import { useState, useEffect } from "react";
import { COVER } from "../../constant/index";
import { useDispatch, useSelector } from "react-redux";
const Navbar = () => {
  const dispatch = useDispatch();
  const [openNotifications, setOpenNotifications] = useState(false);
  const { notificatons } = useSelector((state) => state.stats);
  useEffect(() => {
    dispatch(getNotificationsForAdmin());
  }, []);
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search"></div>
        <div className="items">
          {openNotifications && (
            <div className="modal_overlay_notification">
              {notificatons.map((notificaton, index) => (
                <div className={index > 0 ? "hov2" : "hov"}>
                  <img
                    src={COVER}
                    alt=""
                    crossOrigin="anonymous"
                    className="avatar-not"
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#888",
                    }}
                  >
                    {notificaton?.value}
                  </p>
                </div>
              ))}
            </div>
          )}
          <div className="item"></div>

          <div className="item">
            <NotificationsNoneOutlinedIcon
              className="icon"
              onClick={() => {
                setOpenNotifications(!openNotifications);
                dispatch(readNotifications());
              }}
            />
            {notificatons.filter((e) => !e.isRead).length > 0 && (
              <div className="counter">
                {notificatons.filter((e) => !e.isRead).length}
              </div>
            )}
          </div>
          <div className="item">
            <img
              src={COVER}
              //src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              crossOrigin="anonymous"
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
