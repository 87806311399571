import "./subCategory.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getSubCategory,
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import SubCategoryModal from "../../components/Modal/subCategoryModal/SubCategoryModal";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
const Category = () => {
  const { id } = useParams();
  const {
    subCategoryToEdit,
    isLoadingUpdateSubCategory,
    isErrorUpdateSubCategory,
    categoryList,
  } = useSelector((state) => state.category);
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState(subCategoryToEdit);
  const [successEditSubCategory, setSuccessEditSubCategory] = useState(false);
  useEffect(() => {
    if (subCategoryToEdit === null || subCategoryToEdit._id !== id) {
      dispatch(getSubCategory(id));
    } else {
      setSubCategory(subCategoryToEdit);
    }
  }, [subCategoryToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryList());
      dispatch(getSubCategoryList());
    }
  }, [userInfo]);
  useEffect(() => {
    if (successEditSubCategory) {
      toast.success("subCategory updated succesfully");
      setSuccessEditSubCategory(false);
    }
  }, [successEditSubCategory]);
  const [onEdit, setOnEdit] = useState(false);
  console.log(subCategoryToEdit);
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {isErrorUpdateSubCategory ? (
          <div className="errorContainer">
            <h1 className="errorText"> server error 😞 </h1>
          </div>
        ) : (
          <div className="top">
            <Toaster />
            <div className="left">
              <div
                className="editButton"
                onClick={() => {
                  setOnEdit(true);
                }}
              >
                Edit
              </div>
              <h1 className="title">Information</h1>
              {isLoadingUpdateSubCategory ? (
                <Spinner />
              ) : (
                <div className="item">
                  <div className="details">
                    <h1 className="itemTitle">{subCategoryToEdit?.label}</h1>
                    <div className="detailItem">
                      <span className="itemKey">Description: </span>
                      <span className="itemValue">
                        {subCategoryToEdit?.description}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {onEdit && (
              <SubCategoryModal
                isAdd={false}
                closeModal={() => setOnEdit(false)}
                title={"Edit SubCategory"}
                subCategoryToEdit={subCategoryToEdit}
                loading={isLoadingUpdateSubCategory}
                showSuccessMessage={() => setSuccessEditSubCategory(true)}
                category={subCategoryToEdit?.category}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
