import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../reducers/AuthReducer";
import ProductReducer from "../reducers/ProductReducer";
import CategoryReducer from "../reducers/CategoryReducer";
import StatsReducer from "../reducers/StatsReducer";
import WaletReducer from "../reducers/WaletReducer";
const store = configureStore({
  reducer: {
    auth: AuthReducer,
    product: ProductReducer,
    category: CategoryReducer,
    stats: StatsReducer,
    walet: WaletReducer,
  },
});

export default store;
