import React, { useState } from "react";
import "./CategoryModal.css";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { CategoryValidation } from "../../../validations/categoryValidation";
import { addCategory, updateCategory } from "../../../actions/categoryActions";
const CategoryModal = ({
  closeModal,
  title,
  loading,
  isAdd,
  categoryToEdit,
  showSuccessMessage,
}) => {
  const [height, setHeight] = useState("400px");
  const [category, setCategory] = useState(
    categoryToEdit ? categoryToEdit : null
  );
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    const { errors, isValid } = CategoryValidation(category);
    if (isValid) {
      if (isAdd) {
        dispatch(addCategory(category)).then((res) => {
          if (res.payload?.status) {
            showSuccessMessage();
            closeModal();
          } else {
            toast.error(res.payload?.message);
          }
        });
      } else {
        dispatch(updateCategory(category)).then((res) => {
          if (res.payload?.status) {
            showSuccessMessage();
            closeModal();
          } else {
            toast.error(res.payload?.message);
          }
        });
      }
    } else {
      toast.error(errors);
    }
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setCategory((prev) => ({ ...prev, [id]: value }));
  };
  return (
    <div className="modal_overlay">
      <div className="modal" style={{ height: height }}>
        <div className="modal-header">
          <Toaster />
          <h3>{title}</h3>
          <button className="close-btn" onClick={closeModal}>
            &times;
          </button>
        </div>
        <div
          className="modal-content"
          style={{ marginRight: "5%", marginLeft: "5%" }}
        >
          <form>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">Label</p>
                <input
                  type="text"
                  id="label"
                  placeholder="Label"
                  value={category?.label}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group-admin">
              <div className="input-block">
                <label htmlFor="descriptions">Description</label>
                <textarea
                  id="description"
                  value={category?.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="submit" className="save-btn" onClick={handleSubmit}>
              {loading ? (
                <div className="spinner-container-action-product">
                  <div className="spinner-action-product"></div>
                </div>
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
