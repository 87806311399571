import "./sellerDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteSellerModal,
  setIsServerErrorDeleteSeller,
} from "../../../reducers/AuthReducer";
import { deleteUser } from "../../../actions/authActions";
import Modal from "../../../components/Modal/Modal";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import AddProductModal from "../../../components/Modal/addProductModal/AddProductModal";
import toast, { Toaster } from "react-hot-toast";
import { USER_ROLE_SELLER } from "../../../constant/endpoints";
import { IMAGE_BASE } from "../../../constant/index";
import { useEffect } from "react";
import { getSellerList } from "../../../actions/authActions";
const SellerDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sellerList,
    openDeleteSellerModal,
    isLoadingDeleteSeller,
    isLoadingGetSellers,
    isServerErrorDeleteSeller,
    isServerErrorGetSellers,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (sellerList.length === 0) {
      dispatch(getSellerList());
    }
  }, []);

  const sellerColumns = [
    {
      field: "sellerFullName",
      headerName: "seller",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              crossOrigin="anonymous"
              src={IMAGE_BASE + params.row.picture}
              alt="avatar"
            />
            {params.row.sellerFullName}
          </div>
        );
      },
    },
    {
      field: "companyName",
      headerName: "CompanyName",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "phone",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            {params.row.isVerified ? (
              <div className={`cellWithStatus active`}>active</div>
            ) : (
              <div className={`cellWithStatus pending`}>pending</div>
            )}
          </>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sellerToAddProd, setSellerToAddProd] = useState(null);
  const [addProductOpened, setAddProductOpened] = useState(false);
  const [successAddProduct, setSuccessAddProduct] = useState(false);
  const handleDelete = () => {
    const data = {
      id: itemToDelete._id,
      role: USER_ROLE_SELLER,
    };
    dispatch(deleteUser(data));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1.25,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={async () => {
                setItemToDelete(params.row);
                if (isServerErrorDeleteSeller) {
                  await dispatch(
                    setIsServerErrorDeleteSeller({ value: false })
                  );
                  dispatch(setOpenDeleteSellerModal({ value: true }));
                } else {
                  dispatch(setOpenDeleteSellerModal({ value: true }));
                }
              }}
            >
              Delete
            </div>
            <div
              className="addButton"
              onClick={() => {
                setSellerToAddProd(params.row);
                setAddProductOpened(true);
              }}
            >
              + Product
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (successAddProduct) {
      toast.success("product added succesfully");
      setSuccessAddProduct(false);
    }
  }, [successAddProduct]);
  return (
    <div className="datatable">
      <Toaster />
      <div className="datatableTitle">List Sellers</div>
      {openDeleteSellerModal && (
        <Modal
          isOpen={openDeleteSellerModal}
          onCancel={() => dispatch(setOpenDeleteSellerModal({ value: false }))}
          onConfirm={handleDelete}
          itemName={itemToDelete?.firstName}
          isLoading={isLoadingDeleteSeller}
          isError={isServerErrorDeleteSeller}
          onOk={() => {
            dispatch(setOpenDeleteSellerModal({ value: false }));
            dispatch(setIsServerErrorDeleteSeller({ value: false }));
          }}
        />
      )}
      {isLoadingGetSellers && <LoadingGettingItems />}
      {!isServerErrorGetSellers &&
        !openDeleteSellerModal &&
        !isLoadingGetSellers && (
          <DataGrid
            className="datagrid"
            rows={sellerList}
            columns={sellerColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            //checkboxSelection
          />
        )}
      {isServerErrorGetSellers && (
        <div className="errorContainer">
          <h1 className="errorText"> server error 😞 </h1>
        </div>
      )}
      {addProductOpened && (
        <AddProductModal
          showSuccessMessage={() => setSuccessAddProduct(true)}
          closeModal={() => {
            setAddProductOpened(false);
          }}
          seller={sellerToAddProd}
        />
      )}
    </div>
  );
};

export default SellerDatatable;
