import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  api,
  API_GET_ALL_PRODUCTS,
  API_GET_PRODUCT,
  API_DELETE_PRODUCT,
  API_UPDATE_PRODUCT,
  API_ADD_PRODUCT,
} from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";
export const getProductist = createAsyncThunk(
  API_GET_ALL_PRODUCTS,
  async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_ALL_PRODUCTS}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getProduct = createAsyncThunk(API_GET_PRODUCT, async (id) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_PRODUCT}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const deleteProduct = createAsyncThunk(
  API_DELETE_PRODUCT,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.delete(`${API_DELETE_PRODUCT}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const updateProduct = createAsyncThunk(
  API_UPDATE_PRODUCT,
  async (product) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.put(`${API_UPDATE_PRODUCT}${product._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: product,
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const addProduct = createAsyncThunk(API_ADD_PRODUCT, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const formData = new FormData();
    formData.append("brandName", data.brandName);
    formData.append("label", data.label);
    formData.append("subcategory", data?.subcategory);
    formData.append("price", data.price);
    formData.append("reference", data.reference);
    formData.append("description", data.description);
    formData.append("pictures1", data.selectedPicture1);
    formData.append("pictures2", data.selectedPicture2);
    formData.append("pictures3", data.selectedPicture3);
    formData.append("sizes", data.sizes);
    formData.append("colors", data.colors);
    formData.append("seller", data.seller);
    const response = await api.post(`${API_ADD_PRODUCT}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
});
