import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, API_GET_ALL_WALETS } from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";

export const getAllWalets = createAsyncThunk(API_GET_ALL_WALETS, async () => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_ALL_WALETS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
