import React, { useState } from "react";
import "./AddAdminModal.css";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { AdminValidation } from "../../../validations/adminValidation";
import { add_admin } from "../../../actions/authActions";
const AddAdminModal = ({ closeModal }) => {
  const { isLoadingAddAdmin } = useSelector((state) => state.auth);
  const [height, setHeight] = useState("600px");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
    };
    const { errors, isValid } = AdminValidation(data);
    if (isValid) {
      dispatch(add_admin(data)).then((res) => {
        if (res.payload?.status) {
          closeModal();
        } else {
          toast.error(res.payload?.message);
        }
      });
    } else {
      toast.error(errors);
    }
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    if (id === "firstName") setFirstName(value);
    if (id === "lastName") setLastName(value);
    if (id === "email") setEmail(value);
    if (id === "phone") setPhone(value);
    if (id === "password") setPassword(value);
    if (id === "confirmPassword") setConfirmPassword(value);
  };
  return (
    <div className="modal_overlay">
      <div className="modal" style={{ height: height }}>
        <div className="modal-header">
          <Toaster />
          <h3>Add New Admin</h3>
          <button className="close-btn" onClick={closeModal}>
            &times;
          </button>
        </div>
        <div
          className="modal-content"
          style={{ marginRight: "5%", marginLeft: "5%" }}
        >
          <form>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">First Name</p>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-block">
                <p className="label-input-add-admin"> Last Name</p>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">Email</p>
                <input
                  id="email"
                  value={email}
                  onChange={handleInputChange}
                  type="email"
                />
              </div>
            </div>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">Phone</p>
                <input
                  id="phone"
                  value={phone}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
            </div>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">Password</p>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group-admin">
              <div className="input-block">
                <p className="label-input-add-admin">Confirm Password</p>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <button type="submit" className="save-btn" onClick={handleSubmit}>
              {isLoadingAddAdmin ? (
                <div className="spinner-container-action-product">
                  <div className="spinner-action-product"></div>
                </div>
              ) : (
                "Save Admin"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;
