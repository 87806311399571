export const USER_TOKEN = "user_token";
export const USER_EMAIL = "user_email";
export const IMAGE_BASE = "https://prod.marketme-app.com/images/";
//export const IMAGE_BASE = "http://localhost:8000/images/";
export const COVER = `${IMAGE_BASE}marketMe.jpg`;
/// list of colors :
export const colors = [
  { code: "#FF0000", name: "Red" },
  { code: "#00FF00", name: "Green" },
  { code: "#0000FF", name: "Blue" },
  { code: "#FFFF00", name: "Yellow" },
  { code: "#FFA500", name: "Orange" },
  { code: "#800080", name: "Purple" },
  { code: "#FFC0CB", name: "Pink" },
  { code: "#808080", name: "Gray" },
  { code: "#000000", name: "Black" },
  { code: "#FFFFFF", name: "White" },
  { code: "#008000", name: "DarkGreen" },
  { code: "#800000", name: "Maroon" },
  { code: "#808000", name: "Olive" },
  { code: "#FF6347", name: "Tomato" },
  { code: "#4682B4", name: "SteelBlue" },
  { code: "#A52A2A", name: "Brown" },
  { code: "#00FFFF", name: "Cyan" },
  { code: "#FFD700", name: "Gold" },
  { code: "#4B0082", name: "Indigo" },
  { code: "#FF69B4", name: "HotPink" },
  { code: "#D2691E", name: "Chocolate" },
  { code: "#DC143C", name: "Crimson" },
  { code: "#F0E68C", name: "Khaki" },
  { code: "#ADFF2F", name: "GreenYellow" },
  { code: "#20B2AA", name: "LightSeaGreen" },
  { code: "#B0E0E6", name: "PowderBlue" },
  { code: "#ADD8E6", name: "LightBlue" },
  { code: "#FF4500", name: "OrangeRed" },
  { code: "#2E8B57", name: "SeaGreen" },
  { code: "#FFFFF0", name: "Ivory" },
  { code: "#CD5C5C", name: "IndianRed" },
  { code: "#8B4513", name: "SaddleBrown" },
  { code: "#DEB887", name: "BurlyWood" },
  { code: "#5F9EA0", name: "CadetBlue" },
  { code: "#FF1493", name: "DeepPink" },
  { code: "#696969", name: "DimGray" },
  { code: "#483D8B", name: "DarkSlateBlue" },
  { code: "#2F4F4F", name: "DarkSlateGray" },
  { code: "#FFB6C1", name: "LightPink" },
  { code: "#EEE8AA", name: "PaleGoldenRod" },
  { code: "#98FB98", name: "PaleGreen" },
  { code: "#AFEEEE", name: "PaleTurquoise" },
  { code: "#DB7093", name: "PaleVioletRed" },
  { code: "#4682B4", name: "SteelBlue" },
  { code: "#FA8072", name: "Salmon" },
  { code: "#7FFFD4", name: "Aquamarine" },
  { code: "#40E0D0", name: "Turquoise" },
  { code: "#EE82EE", name: "Violet" },
];
export const subCatygoriesForSizes = [
  "Traditionnel",
  "Sous vêtements / lingeries",
  "Pyjamas",
  "basique",
  "Professionnel",
  "Soirée",
  "Casual",
  "Sport",
  "Sacs",
  "Chaussures",
];
export const subCatygoriesForColors = [
  "Traditionnel",
  "Sous vêtements / lingeries",
  "Pyjamas",
  "basique",
  "Professionnel",
  "Soirée",
  "Casual",
  "Sport",
  "Sacs",
  "Chaussures",
  "Accessoires",
  "Meuble et Décoration",
  "Literie",
  "Linge de maison",
  "Bijoux",
];
export const sizesWithLabels = [
  {
    labels: ["Chaussures"],
    sizes: [
      { value: "20", label: "20" },
      { value: "21", label: "21" },
      { value: "22", label: "22" },
      { value: "23", label: "23" },
      { value: "24", label: "24" },
      { value: "25", label: "25" },
      { value: "26", label: "26" },
      { value: "27", label: "27" },
      { value: "28", label: "28" },
      { value: "29", label: "29" },
      { value: "30", label: "30" },
      { value: "31", label: "31" },
      { value: "32", label: "32" },
      { value: "33", label: "33" },
      { value: "34", label: "34" },
      { value: "35", label: "35" },
      { value: "36", label: "36" },
      { value: "37", label: "37" },
      { value: "38", label: "38" },
      { value: "39", label: "39" },
      { value: "40", label: "40" },
      { value: "41", label: "41" },
      { value: "42", label: "42" },
      { value: "43", label: "43" },
      { value: "44", label: "44" },
      { value: "45", label: "45" },
      { value: "46", label: "46" },
    ],
  },
  {
    labels: ["Professionnel", "Soirée", "Casual", "Sport", "Traditionnel"],
    sizes: [
      { value: "28", label: "28" },
      { value: "30", label: "30" },
      { value: "32", label: "32" },
      { value: "34", label: "34" },
      { value: "36", label: "36" },
      { value: "38", label: "38" },
      { value: "40", label: "40" },
      { value: "42", label: "42" },
      { value: "44", label: "44" },
      { value: "46", label: "46" },
    ],
  },
  {
    labels: ["Pyjamas", "basique", "Sacs", "Sous vêtements / lingeries"],
    sizes: [
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "XXL", label: "XXL" },
      { value: "XXXL", label: "XXXL" },
    ],
  },
];
export const notificatons = [
  {
    isRead: false,
    value: "buyer x register",
  },
  {
    isRead: false,
    value: "reservation has been sent",
  },
  {
    isRead: false,
    value: "buyer x register",
  },
  {
    isRead: false,
    value: "reservation has been sent",
  },
  {
    isRead: false,
    value: "buyer x register",
  },
  {
    isRead: false,
    value: "reservation has been sent",
  },
  {
    isRead: false,
    value: "buyer x register",
  },
  {
    isRead: false,
    value: "reservation has been sent",
  },
  {
    isRead: false,
    value: "buyer x register",
  },
  {
    isRead: false,
    value: "reservation has been sent",
  },
];
