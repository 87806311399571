import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  getBuyerList,
  getSellerList,
  getAdminList,
  updateUser,
  deleteUser,
  getUser,
  add_admin,
  deleteAdmin,
} from "../actions/authActions";
import { USER_ROLE_BUYER, USER_ROLE_SELLER } from "../constant/endpoints";
const initialState = {
  userInfo: null,
  loadingLogin: false,
  problemInLogin: "",
  sellerList: [],
  sellerToEdit: null,
  categoriesNamesOfSeller: [],
  isLoadingGetSellers: false,
  isLoadingDeleteSeller: false,
  isLoadingUpdateSeller: false,
  isServerErrorGetSellers: false,
  isServerErrorDeleteSeller: false,
  isServerErrorUpdateSeller: false,
  buyerList: [],
  buyerToEdit: null,
  isLoadingGetBuyers: false,
  isLoadingDeleteBuyer: false,
  isLoadingUpdateBuyer: false,
  isServerErrorGetBuyers: true,
  isServerErrorDeleteBuyer: false,
  isServerErrorUpdateBuyer: false,
  openDeleteModal: false, // for buyer
  openDeleteSellerModal: false,
  adminList: [],
  isLoadingGetAdmins: false,
  isLoadingDeleteAdmin: false,
  isLoadingAddAdmin: false,
  isServerErrorDeleteAdmin: false,
  isServerErrorGetAdmins: null,
  openDeleteAdminModal: false,
  serverErrorAddAdmin: null,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearUserInfo: (state, action) => {
      state.userInfo = null;
    },
    setUserInfo: (state, action) => {
      const { value } = action.payload;
      state.userInfo = value;
    },
    setProblemInLogin: (state, action) => {
      const { value } = action.payload;
      state.problemInLogin = value;
    },
    setOpenDeleteModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteModal = value;
    },
    setOpenDeleteSellerModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteSellerModal = value;
    },
    setOpenDeleteAdminModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteAdminModal = value;
    },
    logout: (state, action) => {
      state.userInfo = null;
      localStorage.clear();
    },
    setIsServerErrorDeleteBuyer: (state, action) => {
      const { value } = action.payload;
      state.isServerErrorDeleteBuyer = value;
    },
    setIsServerErrorDeleteAdmin: (state, action) => {
      const { value } = action.payload;
      state.isServerErrorDeleteAdmin = value;
    },
    setIsServerErrorDeleteSeller: (state, action) => {
      const { value } = action.payload;
      state.isServerErrorDeleteSeller = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loadingLogin = true;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.userInfo = payload.data;
          window.location.href = "/";
        } else {
          state.problemInLogin = payload?.message;
        }
        state.loadingLogin = false;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loadingLogin = true;
        state.problemInLogin = "error server";
      })
      .addCase(add_admin.pending, (state) => {
        state.isLoadingAddAdmin = true;
      })
      .addCase(add_admin.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.adminList.push(payload?.data);
        }
        state.isLoadingAddAdmin = false;
      })
      .addCase(add_admin.rejected, (state, { payload }) => {
        state.isLoadingAddAdmin = false;
      })
      .addCase(getAdminList.pending, (state) => {
        state.isLoadingGetAdmins = true;
      })
      .addCase(getAdminList.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.adminList = payload.data;
        } else {
          state.isServerErrorGetAdmins = payload?.message;
        }
        state.isLoadingGetAdmins = false;
      })
      .addCase(getAdminList.rejected, (state, payload) => {
        state.isLoadingGetAdmins = false;
      })
      .addCase(getBuyerList.pending, (state) => {
        state.isLoadingGetBuyers = true;
        state.isServerErrorGetBuyers = false;
      })
      .addCase(getBuyerList.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let list = [];
          list = payload.data;
          for (let index = 0; index < list.length; index++) {
            let element = list[index];
            element.id = index + 1;
            element.buyerFullName = `${element.firstName}${element.lastName}`;
          }
          state.buyerList = list;
        } else {
          state.isServerErrorGetBuyers = true;
        }
        state.isLoadingGetBuyers = false;
      })
      .addCase(getBuyerList.rejected, (state, payload) => {
        state.isLoadingGetBuyers = false;
      })
      .addCase(getSellerList.pending, (state) => {
        state.isLoadingGetSellers = true;
        state.isServerErrorGetSellers = false;
      })
      .addCase(getSellerList.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let list = [];
          list = payload.data;
          for (let index = 0; index < list.length; index++) {
            let element = list[index];
            element.id = index + 1;
            element.sellerFullName = `${element.firstName}${element.lastName}`;
          }
          state.sellerList = list;
        } else {
          state.isServerErrorGetSellers = true;
        }
        state.isLoadingGetSellers = false;
      })
      .addCase(getSellerList.rejected, (state, { payload }) => {
        state.isLoadingGetSellers = false;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoadingUpdateBuyer = true;
        state.isLoadingUpdateSeller = true;
        state.isServerErrorUpdateBuyer = false;
        state.isServerErrorUpdateSeller = false;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          if (payload?.data?.role === USER_ROLE_BUYER) {
            state.buyerToEdit = payload?.data;
          } else {
            const seller = payload?.data;
            state.sellerToEdit = seller;
            state.categoriesNamesOfSeller = seller.category.map(
              (cat) => cat.label
            );
          }
        } else {
          state.isServerErrorUpdateBuyer = true;
          state.isServerErrorUpdateSeller = true;
        }
        state.isLoadingUpdateBuyer = false;
        state.isLoadingUpdateSeller = false;
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.isLoadingUpdateBuyer = false;
        state.isLoadingUpdateSeller = false;
      })
      .addCase(updateUser.pending, (state, action) => {
        const data = action.meta.arg;
        if (data?.role === USER_ROLE_SELLER) {
          state.isLoadingUpdateSeller = true;
          state.isServerErrorUpdateSeller = false;
        } else {
          state.isLoadingUpdateBuyer = true;
          state.isServerErrorUpdateBuyer = false;
        }
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const user = payload.data;
          if (user.role === USER_ROLE_BUYER) {
            state.buyerToEdit = user;
            state.buyerList = state.buyerList?.map((buyer) => {
              if (user._id === buyer._id) {
                let element = user;
                element.id = buyer.id;
                return element;
              } else return buyer;
            });
            state.isLoadingUpdateBuyer = false;
          }
          if (user.role === USER_ROLE_SELLER) {
            state.sellerToEdit = user;
            state.categoriesNamesOfSeller = user.category.map(
              (cat) => cat.label
            );
            state.sellerList = state.sellerList?.map((seller) => {
              if (user._id === seller._id) {
                let element = user;
                element.id = seller.id;
                element.sellerFullName = `${user.firstName}${user.lastName}`;
                return element;
              } else return seller;
            });
            state.isLoadingUpdateSeller = false;
          }
        } else {
          state.isServerErrorUpdateBuyer = true;
          state.isServerErrorUpdateSeller = true;
        }
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isLoadingUpdateBuyer = false;
        state.isLoadingUpdateSeller = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoadingDeleteBuyer = true;
        state.isLoadingDeleteSeller = true;
        state.isServerErrorDeleteBuyer = false;
        state.isServerErrorDeleteSeller = false;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          console.log(res);
          if (res.role === USER_ROLE_BUYER) {
            state.buyerList = state.buyerList.filter(
              (item) => item._id !== res.id
            );
          }
          if (res.role === USER_ROLE_SELLER) {
            state.sellerList = state.sellerList.filter(
              (item) => item._id !== res.id
            );
          }
          state.openDeleteModal = false;
          state.openDeleteSellerModal = false;
        } else {
          state.isServerErrorDeleteBuyer = true;
          state.isServerErrorDeleteSeller = true;
        }
        state.isLoadingDeleteBuyer = false;
        state.isLoadingDeleteSeller = false;
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.isLoadingDeleteBuyer = false;
        state.isLoadingDeleteSeller = false;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.isLoadingDeleteAdmin = true;
        state.isServerErrorDeleteAdmin = false;
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          state.adminList = state.adminList.filter(
            (item) => item._id !== res.id
          );
          state.openDeleteAdminModal = false;
        } else {
          state.isServerErrorDeleteAdmin = true;
        }
        state.isLoadingDeleteAdmin = false;
      })
      .addCase(deleteAdmin.rejected, (state, { payload }) => {
        state.isLoadingDeleteAdmin = false;
      });
  },
});
export const {
  setUserInfo,
  setProblemInLogin,
  setOpenDeleteModal,
  setOpenDeleteSellerModal,
  setOpenDeleteAdminModal,
  setIsServerErrorDeleteBuyer,
  setIsServerErrorDeleteSeller,
  setIsServerErrorDeleteAdmin,
  logout,
} = authReducer.actions;
export default authReducer.reducer;
