import { createSlice } from "@reduxjs/toolkit";
import {
  getCategoryList,
  getSubCategoryList,
  updateCategory,
  deleteCategory,
  getCategoryById,
  addSubCategory,
  deleteSubCategory,
  getSubCategory,
  updateSubCategory,
  addCategory,
} from "../actions/categoryActions";
const initialState = {
  categoryList: [],
  categoryListNames: [],
  subCategoryList: [],
  categoryListToExecute: [],
  categoryToEdit: null,
  subCategoryToEdit: null,
  openDeleteCategoryModal: false,
  openDeleteSubCategoryModal: false,
  // loading
  isLoadingGetCategories: false,
  isLoadingGetCategory: false,
  isLoadingDeleteCategory: false,
  isLoadingUpdateCategory: false,
  isLoadingAddSubCategory: false,
  isLoadingDeleteSubCategory: false,
  isLoadingUpdateSubCategory: false,
  isLoadingAddCategory: false,
  isLoadingGetSubCategory: false,
  ///
  labelDeleted: "",
  //error
  isErrorGetCategories: false,
  isErrorGetCategory: false,
  isErrorDeleteCategory: false,
  isErrorDeleteSubCategory: false,
  isErrorUpdateSubCategory: false,
};

const categoryReducer = createSlice({
  name: "category",
  initialState,
  reducers: {
    setOpenDeleteCategoryModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteCategoryModal = value;
    },
    setOpenDeleteSubCategoryModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteSubCategoryModal = value;
    },
    setIsErrorDeleteCategory: (state, action) => {
      const { value } = action.payload;
      state.isErrorDeleteCategory = value;
    },
    setIsErrorDeleteSubCategory: (state, action) => {
      const { value } = action.payload;
      state.isErrorDeleteSubCategory = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, (state) => {
        state.isLoadingGetCategories = true;
        state.isErrorGetCategories = false;
      })
      .addCase(getCategoryList.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let list = payload.data.reverse();
          state.categoryList = list;
          state.categoryListNames = list.map((cat) => cat.label);
          state.categoryListToExecute = list;
        } else {
          state.isErrorGetCategories = true;
        }
        state.isLoadingGetCategories = false;
      })
      .addCase(getCategoryList.rejected, (state, { payload }) => {
        state.isLoadingGetCategories = false;
      })
      .addCase(getCategoryById.pending, (state) => {
        state.isLoadingGetCategory = true;
        state.isErrorGetCategory = false;
      })
      .addCase(getCategoryById.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let x = payload?.data;
          x.subCategories = x.subCategories.reverse();
          state.categoryToEdit = x;
        } else {
          state.isErrorGetCategory = true;
        }
        state.isLoadingGetCategory = false;
      })
      .addCase(getCategoryById.rejected, (state, { payload }) => {
        state.isLoadingGetCategory = false;
      })
      .addCase(addCategory.pending, (state) => {
        state.isLoadingAddCategory = true;
      })
      .addCase(addCategory.fulfilled, (state, { payload }) => {
        console.log(payload);
        if (payload?.status) {
          let newCategory = payload?.data;
          state.categoryListToExecute = [
            newCategory,
            ...state.categoryListToExecute,
          ];
          state.categoryList = [newCategory, ...state.categoryList];
          state.categoryListNames = [
            newCategory.label,
            ...state.categoryListNames,
          ];
        }
        state.isLoadingAddCategory = false;
      })
      .addCase(addCategory.rejected, (state, { payload }) => {
        state.isLoadingAddCategory = false;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoadingUpdateCategory = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const updatedCategory = payload.data;
          state.categoryToEdit = updatedCategory;
          state.categoryList = state.categoryList?.map((category) => {
            if (updatedCategory._id === category._id) {
              let element = updatedCategory;
              return element;
            } else return category;
          });
          state.categoryListNames = state.categoryList.map((cat) => cat.label);
          state.categoryListToExecute = state.categoryListToExecute?.map(
            (category) => {
              if (updatedCategory._id === category._id) {
                let element = updatedCategory;
                return element;
              } else return category;
            }
          );
        }
        state.isLoadingUpdateCategory = false;
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.isLoadingUpdateCategory = false;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isLoadingDeleteCategory = true;
        state.isErrorDeleteCategory = false;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          const T = state.categoryList.filter((item) => item._id === res.id);
          state.labelDeleted = T[0].label;
          state.categoryList = state.categoryList.filter(
            (item) => item._id !== res.id
          );
          state.categoryListToExecute = state.categoryListToExecute.filter(
            (item) => item._id !== res.id
          );
          state.categoryListNames = state.categoryListNames.filter(
            (item) => item !== state.labelDeleted
          );
          state.subCategoryList = state.subCategoryList.filter(
            (item) => item?.category?._id !== res.id
          );
          state.openDeleteCategoryModal = false;
        } else {
          state.isErrorDeleteCategory = true;
        }
        state.isLoadingDeleteCategory = false;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.isLoadingDeleteCategory = false;
      })
      .addCase(getSubCategoryList.pending, (state) => {})
      .addCase(getSubCategoryList.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.subCategoryList = payload.data;
        }
      })
      .addCase(getSubCategoryList.rejected, (state, { payload }) => {})
      .addCase(addSubCategory.pending, (state) => {
        state.isLoadingAddSubCategory = true;
      })
      .addCase(addSubCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          if (state.categoryToEdit.subCategories) {
            state.categoryToEdit.subCategories = [
              payload?.data,
              ...state.categoryToEdit.subCategories,
            ];
          }
        }
        state.isLoadingAddSubCategory = false;
      })
      .addCase(addSubCategory.rejected, (state, { payload }) => {
        state.isLoadingAddSubCategory = false;
      })
      .addCase(deleteSubCategory.pending, (state) => {
        state.isLoadingDeleteSubCategory = true;
        state.isErrorDeleteSubCategory = false;
      })
      .addCase(deleteSubCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          state.categoryToEdit.subCategories =
            state.categoryToEdit.subCategories.filter(
              (item) => item._id !== res.id
            );
          state.openDeleteSubCategoryModal = false;
        } else {
          state.isErrorDeleteSubCategory = true;
        }
        state.isLoadingDeleteSubCategory = false;
      })
      .addCase(deleteSubCategory.rejected, (state, { payload }) => {
        state.isLoadingDeleteSubCategory = false;
      })
      .addCase(getSubCategory.pending, (state) => {
        state.isLoadingUpdateSubCategory = true;
        state.isErrorUpdateSubCategory = false;
      })
      .addCase(getSubCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.subCategoryToEdit = payload?.data;
        } else {
          state.isErrorUpdateSubCategory = true;
        }
        state.isLoadingUpdateSubCategory = false;
      })
      .addCase(getSubCategory.rejected, (state, { payload }) => {
        state.isLoadingUpdateSubCategory = false;
      })
      .addCase(updateSubCategory.pending, (state) => {
        state.isLoadingUpdateSubCategory = true;
        state.isErrorUpdateSubCategory = false;
      })
      .addCase(updateSubCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.categoryToEdit = null;
          state.subCategoryToEdit = payload.data;
        } else {
          state.isErrorUpdateSubCategory = true;
        }
        state.isLoadingUpdateSubCategory = false;
      })
      .addCase(updateSubCategory.rejected, (state, { payload }) => {
        state.isLoadingUpdateSubCategory = false;
      });
  },
});

export const {
  setOpenDeleteCategoryModal,
  setOpenDeleteSubCategoryModal,
  setIsErrorDeleteCategory,
  setIsErrorDeleteSubCategory,
} = categoryReducer.actions;
export default categoryReducer.reducer;
