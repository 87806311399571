import { createSlice } from "@reduxjs/toolkit";
import {
  getProductist,
  deleteProduct,
  updateProduct,
  getProduct,
  addProduct,
} from "../actions/productActions";
const initialState = {
  productList: [],
  isLoadingGetProducts: false,
  isLoadingDeleteProduct: false,
  isLoadingUpdateProduct: false,
  isLoadingAddProduct: false,
  productToEdit: null,
  openDeleteProductModal: false,
  problemUpdateProduct: "",
  isServerErrorGetProducts: false,
  isServerErrorDeleteProduct: false,
  isServerErrorUpdateProduct: false,
};

const productReducer = createSlice({
  name: "product",
  initialState,
  reducers: {
    setOpenDeleteProductModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteProductModal = value;
    },
    setProblemInUpdateProduct: (state, action) => {
      const { value } = action.payload;
      state.problemUpdateProduct = value;
    },
    setIsServerErrorDeleteProduct: (state, action) => {
      const { value } = action.payload;
      state.isServerErrorDeleteProduct = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductist.pending, (state) => {
        state.isLoadingGetProducts = true;
        state.isServerErrorGetProducts = false;
      })
      .addCase(getProductist.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.productList = payload.data.reverse().map((el) => {
            let newEL = el;
            newEL.sellerFullName = `${el?.seller?.firstName} ${el?.seller?.lastName}`;
            return newEL;
          });
        } else {
          state.isServerErrorGetProducts = true;
        }
        state.isLoadingGetProducts = false;
      })
      .addCase(getProductist.rejected, (state, { payload }) => {
        state.isLoadingGetProducts = false;
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoadingUpdateProduct = true;
        state.isServerErrorUpdateProduct = false;
      })
      .addCase(getProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.productToEdit = payload?.data;
        } else {
          state.isServerErrorUpdateProduct = true;
        }
        state.isLoadingUpdateProduct = false;
      })
      .addCase(getProduct.rejected, (state, { payload }) => {
        state.isLoadingUpdateProduct = false;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoadingDeleteProduct = true;
        state.isServerErrorDeleteProduct = false;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          state.productList = state.productList.filter(
            (item) => item._id !== res.id
          );
          state.openDeleteProductModal = false;
        } else {
          state.isServerErrorDeleteProduct = true;
        }
        state.isLoadingDeleteProduct = false;
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.isLoadingDeleteProduct = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.isLoadingUpdateProduct = true;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const updatedProduct = payload.data;
          state.productToEdit = updatedProduct;
          state.productList = state.productList?.map((prod) => {
            if (updatedProduct._id === prod._id) {
              let el = updateProduct;
              el.sellerFullName = `${updateProduct?.seller?.firstName} ${updateProduct?.seller?.lastName}`;
              return el;
            } else return prod;
          });
        } else {
          if (payload.message) {
            state.problemUpdateProduct = payload.message;
          } else {
            state.isServerErrorUpdateProduct = true;
          }
        }
        state.isLoadingUpdateProduct = false;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.isLoadingUpdateProduct = false;
      })
      .addCase(addProduct.pending, (state) => {
        state.isLoadingAddProduct = true;
      })
      .addCase(addProduct.fulfilled, (state, { payload }) => {
        if (payload?.status && payload?.code == 201) {
          let newEL = payload.product;
          newEL.sellerFullName = `${payload.product?.seller?.firstName} ${payload.product?.seller?.lastName}`;
          state.productList = [payload.product, ...state.productList];
          //tate.productList.push(payload?.product);
        }
        state.isLoadingAddProduct = false;
      })
      .addCase(addProduct.rejected, (state, { payload }) => {
        state.isLoadingAddProduct = false;
      });
  },
});

export const {
  setOpenDeleteProductModal,
  setProblemInUpdateProduct,
  setIsServerErrorDeleteProduct,
} = productReducer.actions;
export default productReducer.reducer;
