const isEmpty = (value) =>
  value === null ||
  value === undefined ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const CategoryValidation = (data) => {
  let errors = {};
  if (!data?.description || data?.description.trim() === "") {
    errors = "Description is required";
  }
  if (!data?.label || data?.label.trim() === "") {
    errors = "Label is required";
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};
