import React, { useState, useEffect } from "react";
import "./AddProductModal.css";
import Camera from "../../svgs/camera";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  IMAGE_BASE,
  colors,
  subCatygoriesForSizes,
  subCatygoriesForColors,
  sizesWithLabels,
} from "../../../constant";
import { ProductValidation } from "../../../validations/productValidation";
import { addProduct } from "../../../actions/productActions";
import Select from "react-select";
const AddProductModal = ({ closeModal, seller, showSuccessMessage }) => {
  const { subCategoryList } = useSelector((state) => state.category);
  const { isLoadingAddProduct } = useSelector((state) => state.product);
  const [height, setHeight] = useState("850px");
  const [selectedPicture1, setSelectedPicture1] = useState(null);
  const [selectedPicture2, setSelectedPicture2] = useState(null);
  const [selectedPicture3, setSelectedPicture3] = useState(null);

  const [picture1, setPicture1] = useState(null);
  const [picture2, setPicture2] = useState(null);
  const [picture3, setPicture3] = useState(null);

  const [brandName, setBrandName] = useState("");
  const [label, setLabel] = useState("");
  const [price, setPrice] = useState(0);
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");

  const [categorySelected, setCategorySelected] = useState(
    seller?.category ? seller?.category[0] : null
  );
  const [subcategorySelected, setSubcategorySelected] = useState(
    subCategoryList.find((el) => el?.category?._id == categorySelected?._id)
      .subcategories[0]
  );
  const [subCategoriesToShow, setSubCategoriesToShow] = useState(
    subCategoryList.find((el) => el?.category?._id == categorySelected._id)
      .subcategories
  );
  const [optionSizes, setOptionSizes] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  useEffect(() => {
    if (subCatygoriesForSizes.includes(subcategorySelected?.label)) {
      setSelectedSizes([]);
      const sizesToDisplay = sizesWithLabels.find((sl) =>
        sl.labels.includes(subcategorySelected?.label)
      ).sizes;
      setOptionSizes(sizesToDisplay);
    }
  }, [subcategorySelected]);

  const handleSizeChange = (selectedOptions) => {
    setSelectedSizes(selectedOptions);
  };
  const colorOptions = colors.map((color) => ({
    value: color.code,
    label: color.name,
    color: color.code,
  }));
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected
        ? state.data.color
        : provided.backgroundColor,
      color: state.isSelected ? "#fff" : provided.color,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "0 5px",
      backgroundColor: state.data.color,
      color: "#fff",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#fff",
      ":hover": {
        backgroundColor: state.data.color,
        color: "#000",
      },
    }),
  };
  const ColourOption = (props) => (
    <div {...props.innerProps} style={props.getStyles("option", props)}>
      <div
        style={{
          width: 15,
          height: 15,
          borderRadius: "50%",
          backgroundColor: props.data.color,
          marginRight: 10,
        }}
      />
      {props.data.name}
    </div>
  );

  const MultiValue = (props) => (
    <div {...props.innerProps} style={props.getStyles("multiValue", props)}>
      <div
        style={{
          width: 15,
          height: 15,
          borderRadius: "50%",
          backgroundColor: props.data.color,
          marginRight: 10,
        }}
      />
      <span>{props.data.label}</span>
    </div>
  );
  const [selectedColors, setSelectedColors] = useState([]);

  const handleColorChange = (selectedOptions) => {
    setSelectedColors(selectedOptions);
  };
  const dispatch = useDispatch();
  const handleFileSelect = (file, wichPicture) => {
    // const file = event.target.files[0];
    if (file) {
      if (wichPicture === "1") {
        setSelectedPicture1(file);
        setPicture1(file.name);
      }
      if (wichPicture === "2") {
        setSelectedPicture2(file);
        setPicture2(file.name);
      }
      if (wichPicture === "3") {
        setSelectedPicture3(file);
        setPicture3(file.name);
      }
    }
  };
  const openFileDialog = (wichPicture) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      handleFileSelect(file, wichPicture);
    };
    // input.onchange = handleFileSelect;
    input.click();
  };
  const cancelSelectingPicture = (pictureToUpload: string) => {
    if (pictureToUpload === "1") {
      setSelectedPicture1(null);
      setPicture1(null);
    }
    if (pictureToUpload === "2") {
      setSelectedPicture2(null);
      setPicture2(null);
    }
    if (pictureToUpload === "3") {
      setSelectedPicture3(null);
      setPicture3(null);
    }
  };
  const getDefaultSubCategory = (categoryId) => {
    const res = subCategoryList.find((el) => el?.category?._id == categoryId);
    if (res) {
      setSubcategorySelected(res.subcategories[0]);
      setSubCategoriesToShow(res.subcategories);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      brandName,
      label,
      price,
      reference,
      description,
      selectedPicture1,
      selectedPicture2,
      selectedPicture3,
      picture1,
      picture2,
      picture3,
      subcategory: subcategorySelected._id,
      subcategoryLabel: subcategorySelected.label,
      sizes: selectedSizes.map((s) => s.value),
      colors: selectedColors.map((c) => c.value),
    };
    const { errors, isValid } = ProductValidation(data);
    console.log(errors);
    if (isValid) {
      if (seller._id) {
        data.seller = seller._id;
        dispatch(addProduct(data)).then(async (res) => {
          if (res.payload?.status) {
            showSuccessMessage();
            closeModal();
          } else {
            toast.error(res.payload?.message);
          }
        });
      } else {
        toast.error("no seller linked please go back and try again");
      }
    } else {
      toast.error(errors);
    }
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    if (id === "brandName") setBrandName(value);
    if (id === "label") setLabel(value);
    if (id === "price") setPrice(value);
    if (id === "reference") setReference(value);
    if (id === "description") setDescription(value);
  };
  const handleCategoryChange = (event) => {
    const x = seller?.category.find((el) => el._id == event.target.value);
    if (x) {
      setCategorySelected(x);
      getDefaultSubCategory(x._id);
    }
  };
  const handleSubCategoryChange = (event) => {
    const x = subCategoriesToShow.find((el) => el._id == event.target.value);
    if (x) {
      setSubcategorySelected(x);
    }
  };
  return (
    <div className="modal_overlay">
      <div className="modal" style={{ height: height }}>
        <div className="modal-header">
          <Toaster />
          <h3>Add New Product</h3>
          <button className="close-btn" onClick={closeModal}>
            &times;
          </button>
        </div>
        <div
          className="modal-content"
          style={{ marginRight: "5%", marginLeft: "5%" }}
        >
          <div className="image-section">
            <div className="image-wrapper">
              {picture1 === null ? (
                <div className="image-upload">
                  <button
                    className="upload-btn"
                    onClick={() => openFileDialog("1")}
                  >
                    <Camera />
                  </button>
                </div>
              ) : (
                <>
                  <img
                    src={
                      selectedPicture1 && selectedPicture1.name === picture1
                        ? URL.createObjectURL(selectedPicture1)
                        : IMAGE_BASE + picture2
                    }
                    crossOrigin="anonymous"
                  />

                  <button
                    className="remove-btn"
                    onClick={() => {
                      cancelSelectingPicture("1");
                    }}
                  >
                    &times;
                  </button>
                </>
              )}
            </div>
            <div className="image-wrapper">
              {picture2 === null ? (
                <div className="image-upload">
                  <button
                    className="upload-btn"
                    onClick={() => openFileDialog("2")}
                  >
                    <Camera />
                  </button>
                </div>
              ) : (
                <>
                  <img
                    crossOrigin="anonymous"
                    src={
                      selectedPicture2 && selectedPicture2.name === picture2
                        ? URL.createObjectURL(selectedPicture2)
                        : IMAGE_BASE + picture2
                    }
                  />

                  <button
                    className="remove-btn"
                    onClick={() => {
                      cancelSelectingPicture("2");
                    }}
                  >
                    &times;
                  </button>
                </>
              )}
            </div>
            <div className="image-wrapper">
              {picture3 === null ? (
                <div className="image-upload">
                  <button
                    className="upload-btn"
                    onClick={() => openFileDialog("3")}
                  >
                    <Camera />
                  </button>
                </div>
              ) : (
                <>
                  <img
                    src={
                      selectedPicture3 && selectedPicture3.name === picture3
                        ? URL.createObjectURL(selectedPicture3)
                        : IMAGE_BASE + picture3
                    }
                    crossOrigin="anonymous"
                  />

                  <button
                    className="remove-btn"
                    onClick={() => {
                      cancelSelectingPicture("3");
                    }}
                  >
                    &times;
                  </button>
                </>
              )}
            </div>
          </div>
          <form>
            <div className="form-group">
              <div className="form-subgroup">
                <label htmlFor="BrandName" className="label-input">
                  Brand
                </label>
                <input
                  type="text"
                  id="brandName"
                  placeholder="Brand"
                  value={brandName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-subgroup">
                <label htmlFor="productName" className="label-input">
                  Label
                </label>
                <input
                  type="text"
                  id="label"
                  value={label}
                  placeholder="Label"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {seller?.category && (
              <div className="form-group">
                <div className="form-subgroup">
                  <label htmlFor="category" className="label-input">
                    Category
                  </label>

                  <select
                    id="category"
                    value={categorySelected?._id}
                    onChange={handleCategoryChange}
                  >
                    {seller.category.map((category, index) => (
                      <option key={index} value={category._id}>
                        {category?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-subgroup">
                  <label htmlFor="subCategory" className="label-input">
                    SubCategory
                  </label>
                  <select
                    id="subcategory"
                    value={subcategorySelected?._id}
                    onChange={handleSubCategoryChange}
                  >
                    {subCategoriesToShow.length > 0 &&
                      subCategoriesToShow.map((sub, index) => (
                        <option key={index} value={sub._id}>
                          {sub?.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="form-subgroup">
                <label htmlFor="price" className="label-input">
                  Price
                </label>
                <input
                  type="text"
                  id="price"
                  placeholder="price"
                  value={price}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-subgroup">
                <label htmlFor="stock" className="label-input">
                  Reference
                </label>
                <input
                  type="text"
                  id="reference"
                  placeholder="reference"
                  value={reference}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              {subCatygoriesForSizes.includes(subcategorySelected?.label) && (
                <div
                  className={
                    subCatygoriesForColors.includes(subcategorySelected?.label)
                      ? "form-subgroup"
                      : ""
                  }
                >
                  <label htmlFor="size" className="label-input">
                    Size
                  </label>
                  <Select
                    id="sizes"
                    value={selectedSizes}
                    onChange={handleSizeChange}
                    options={optionSizes}
                    isMulti
                    isClearable
                    placeholder="Select sizes"
                  />
                </div>
              )}
              {subCatygoriesForColors.includes(subcategorySelected?.label) && (
                <div
                  className={
                    subCatygoriesForSizes.includes(subcategorySelected?.label)
                      ? "form-subgroup"
                      : ""
                  }
                >
                  <label htmlFor="colors" className="label-input">
                    Color
                  </label>
                  <Select
                    value={selectedColors}
                    onChange={handleColorChange}
                    options={colorOptions}
                    styles={customStyles}
                    components={{ Option: ColourOption, MultiValue }} // Use custom components for multi-select
                    isMulti
                    isClearable
                  />
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="descriptions">Descriptions</label>
              {/* <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="descriptionGenerator"
                      defaultChecked
                    />
                    <label htmlFor="descriptionGenerator">
                      Description Generator
                    </label>
                  </div> */}
              <textarea
                id="description"
                value={description}
                onChange={handleInputChange}
              />
            </div>

            <button type="submit" className="save-btn" onClick={handleSubmit}>
              {isLoadingAddProduct ? (
                <div className="spinner-container-action-product">
                  <div className="spinner-action-product"></div>
                </div>
              ) : (
                "Save Product"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProductModal;
