const Camera = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4532 3.51406C15.4922 3.58223 15.5605 3.63093 15.6482 3.63093C17.9884 3.63093 19.8995 5.53991 19.8995 7.87743V13.6628C19.8995 16.0003 17.9884 17.9093 15.6482 17.9093H4.64966C2.29979 17.9093 0.398438 16.0003 0.398438 13.6628V7.87743C0.398438 5.53991 2.29979 3.63093 4.64966 3.63093C4.72766 3.63093 4.80567 3.59197 4.83492 3.51406L4.89342 3.39718C4.92703 3.32643 4.96153 3.25377 4.99655 3.18C5.24599 2.6546 5.52196 2.07332 5.69296 1.73169C6.14149 0.855122 6.90203 0.368138 7.84782 0.358398H12.4403C13.3861 0.368138 14.1564 0.855122 14.6049 1.73169C14.7585 2.03849 14.9925 2.53268 15.218 3.009C15.2645 3.10729 15.3107 3.20482 15.3557 3.29978L15.4532 3.51406ZM14.7415 7.25407C14.7415 7.74105 15.1316 8.13064 15.6191 8.13064C16.1066 8.13064 16.5064 7.74105 16.5064 7.25407C16.5064 6.76708 16.1066 6.36776 15.6191 6.36776C15.1316 6.36776 14.7415 6.76708 14.7415 7.25407ZM8.46179 8.76383C8.92006 8.30606 9.51484 8.06257 10.1486 8.06257C10.7824 8.06257 11.3772 8.30606 11.8257 8.75409C12.2742 9.20211 12.518 9.79623 12.518 10.4293C12.5083 11.7344 11.4552 12.7961 10.1486 12.7961C9.51484 12.7961 8.92006 12.5526 8.47154 12.1045C8.02302 11.6565 7.77925 11.0624 7.77925 10.4293V10.4196C7.7695 9.80597 8.01327 9.21185 8.46179 8.76383ZM12.8497 13.1369C12.1575 13.8284 11.2019 14.257 10.1489 14.257C9.12505 14.257 8.1695 13.8576 7.43821 13.1369C6.71667 12.4064 6.3169 11.4519 6.3169 10.4293C6.30715 9.41634 6.70692 8.46186 7.42846 7.73138C8.15975 7.0009 9.12505 6.60158 10.1489 6.60158C11.1727 6.60158 12.138 7.0009 12.8595 7.72164C13.581 8.45212 13.9808 9.41634 13.9808 10.4293C13.9711 11.4909 13.542 12.4454 12.8497 13.1369Z"
    />
  </svg>
);

export default Camera;
