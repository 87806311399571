import { createSlice } from "@reduxjs/toolkit";
import {
  getStatsOfSeller,
  getStats_buyers_sellers,
  getStatsOfApp,
} from "../actions/statsActions";
import { notificatons } from "../constant/index";
const initialState = {
  isLoadingGettingStats: false,
  isProblemGettingStatsSellers: false,
  statsBySeller: null,
  // stats selles and buyers
  isLoadingGettingStats_sellers_buyers: false,
  isProblemGettingStats_sellers_buyers: false,
  statsBy_sellers_buyers: null,
  // stats selles and buyers
  isLoadingGettingStats_sells_reser: false,
  isProblemGettingStats_sells_reser: false,
  stats_sells_reser: null,
  notificatons: [],
};

const statsReducer = createSlice({
  name: "stats",
  initialState,
  reducers: {
    getNotificationsForAdmin: (state) => {
      state.notificatons = notificatons;
    },
    readNotifications: (state) => {
      state.notificatons = state.notificatons.map((el) => {
        let x = el;
        x.isRead = true;
        return x;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatsOfSeller.pending, (state) => {
        state.isLoadingGettingStats = true;
      })
      .addCase(getStatsOfSeller.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.statsBySeller = payload.resData;
          state.isProblemGettingStatsSellers = false;
        } else {
          state.isProblemGettingStatsSellers = true;
        }
        state.isLoadingGettingStats = false;
      })
      .addCase(getStatsOfSeller.rejected, (state, { payload }) => {
        state.isLoadingGettingStats = false;
      })
      .addCase(getStats_buyers_sellers.pending, (state) => {
        state.isLoadingGettingStats_sellers_buyers = true;
      })
      .addCase(getStats_buyers_sellers.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.statsBy_sellers_buyers = payload.resData;
          state.isProblemGettingStats_sellers_buyers = false;
        } else {
          state.isProblemGettingStats_sellers_buyers = true;
        }
        state.isLoadingGettingStats_sellers_buyers = false;
      })
      .addCase(getStats_buyers_sellers.rejected, (state, { payload }) => {
        state.isLoadingGettingStats_sellers_buyers = false;
      })
      .addCase(getStatsOfApp.pending, (state) => {
        state.isLoadingGettingStats_sells_reser = true;
      })
      .addCase(getStatsOfApp.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.stats_sells_reser = payload.resData;
          state.isProblemGettingStats_sells_reser = false;
        } else {
          state.isProblemGettingStats_sells_reser = true;
        }
        state.isLoadingGettingStats_sells_reser = false;
      })
      .addCase(getStatsOfApp.rejected, (state, { payload }) => {
        state.isLoadingGettingStats_sells_reser = false;
      });
  },
});

export const { getNotificationsForAdmin, readNotifications } =
  statsReducer.actions;
export default statsReducer.reducer;
