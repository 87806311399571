import "./adminDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenDeleteAdminModal,
  setIsServerErrorDeleteAdmin,
} from "../../../reducers/AuthReducer";
import { deleteAdmin } from "../../../actions/authActions";
import Modal from "../../../components/Modal/Modal";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import AddAdminModal from "../../../components/Modal/addAdminModal/AddAdminModal";
import { useEffect } from "react";
import { getAdminList } from "../../../actions/authActions";
import { USER_EMAIL } from "../../../constant";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
const AdminDatatable = () => {
  const dispatch = useDispatch();
  const {
    adminList,
    openDeleteAdminModal,
    isLoadingDeleteAdmin,
    isLoadingGetAdmins,
    isServerErrorGetAdmins,
    isServerErrorDeleteAdmin,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (adminList.length === 0) {
      dispatch(getAdminList());
    }
  }, []);
  const adminColumns = [
    {
      field: "firstName",
      headerName: "firstName",
      flex: 1,
    },

    {
      field: "lastName",
      headerName: "lastName",
      flex: 1,
    },
    {
      field: "email",
      headerName: "email",
      flex: 1,
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const [addAdminOpened, setAddAdminOpened] = useState(false);
  const handleDelete = () => {
    dispatch(deleteAdmin(itemToDelete._id));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={async () => {
                if (
                  localStorage.getItem(USER_EMAIL) == params.row.email ||
                  adminList.length == 1
                ) {
                  if (localStorage.getItem(USER_EMAIL) == params.row.email) {
                    toast.error("you can t delete yourself");
                  } else {
                    toast.error("there is only an admin in market me");
                  }
                } else {
                  setItemToDelete(params.row);
                  dispatch(setOpenDeleteAdminModal({ value: true }));
                }
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        List Admins
        <Link className="link" onClick={() => setAddAdminOpened(true)}>
          Add New Admin
        </Link>
      </div>
      <Toaster />
      {openDeleteAdminModal && (
        <Modal
          isOpen={openDeleteAdminModal}
          onCancel={() => dispatch(setOpenDeleteAdminModal({ value: false }))}
          onOk={() => {
            dispatch(setOpenDeleteAdminModal({ value: false }));
            dispatch(setIsServerErrorDeleteAdmin({ value: false }));
          }}
          onConfirm={handleDelete}
          itemName={itemToDelete?.firstName}
          isLoading={isLoadingDeleteAdmin}
          isError={isServerErrorDeleteAdmin}
        />
      )}
      {isServerErrorGetAdmins && (
        <div className="errorContainer">
          <h1 className="errorText"> {isServerErrorGetAdmins} 😞 </h1>
        </div>
      )}
      {addAdminOpened && (
        <AddAdminModal closeModal={() => setAddAdminOpened(false)} />
      )}
      {isLoadingGetAdmins && <LoadingGettingItems />}
      {!isLoadingGetAdmins &&
        !isServerErrorGetAdmins &&
        !openDeleteAdminModal && (
          <DataGrid
            className="datagrid"
            rows={adminList}
            columns={adminColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            getRowId={(row) => row._id}
            //checkboxSelection
          />
        )}
    </div>
  );
};

export default AdminDatatable;
