import { createSlice } from "@reduxjs/toolkit";
import { getAllWalets } from "../actions/waletActions";
const initialState = {
  allWalets: [],
  loadingGetAllWalets: false,
  errorGetWalet: false,
};

const waletReducer = createSlice({
  name: "walet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllWalets.pending, (state) => {
        state.loadingGetAllWalets = true;
        state.errorGetWalet = false;
      })
      .addCase(getAllWalets.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let list = [];
          list = payload.data;
          for (let index = 0; index < list.length; index++) {
            let element = list[index];
            element.id = index + 1;
            element.sellerFullName = `${element.seller.firstName}${element.seller.lastName}`;
            element.subscriptionName = element.subscription?.subscriptionName;
          }
          state.allWalets = list;
        } else {
          state.errorGetWalet = true;
        }
        state.loadingGetAllWalets = false;
      })
      .addCase(getAllWalets.rejected, (state, payload) => {
        state.loadingGetAllWalets = false;
      });
  },
});
export const {} = waletReducer.actions;
export default waletReducer.reducer;
